import React from 'react';
import { arrayOf, bool, func, node, number, object, shape, string } from 'prop-types';
import classNames from 'classnames';
import { LinkedLogo, NamedLink } from '../../../../components';

import Field from '../../Field';
import BlockBuilder from '../../BlockBuilder';

import SectionContainer from '../SectionContainer';
import css from './SectionFooter.module.css';
import { translate } from '../../../../util/translate';
import * as CookieConsent from 'vanilla-cookieconsent';
import { cookieConfig } from '../../../../config/cookieConfig';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import FooterMobile from './FooterMobile';
import StaticFooter from './StaticFooter';

// The number of columns (numberOfColumns) affects styling

const GRID_CONFIG = [
  { contentCss: css.contentCol1, gridCss: css.gridCol1 },
  { contentCss: css.contentCol2, gridCss: css.gridCol2 },
  { contentCss: css.contentCol3, gridCss: css.gridCol3 },
  { contentCss: css.contentCol4, gridCss: css.gridCol4 },
];

const getIndex = numberOfColumns => numberOfColumns - 1;

const getContentCss = numberOfColumns => {
  const contentConfig = GRID_CONFIG[getIndex(numberOfColumns)];
  return contentConfig ? contentConfig.contentCss : GRID_CONFIG[0].contentCss;
};

const getGridCss = numberOfColumns => {
  const contentConfig = GRID_CONFIG[getIndex(numberOfColumns)];
  return contentConfig ? contentConfig.gridCss : GRID_CONFIG[0].gridCss;
};

// Section component that's able to show blocks in multiple different columns (defined by "numberOfColumns" prop)
const SectionFooter = props => {
  useEffect(() => {
    CookieConsent.run(cookieConfig);
  }, []);
  const {
    sectionId,
    className,
    rootClassName,
    numberOfColumns,
    socialMediaLinks,
    slogan,
    appearance,
    copyright,
    blocks,
    options,

    isFooter,
  } = props;
  // console.log('blocks', blocks);
  const newSlogan = translate(slogan);
  const newCopyright = translate(copyright);

  const fieldComponents = options?.fieldComponents;
  const fieldOptions = { fieldComponents };
  const linksWithBlockId = socialMediaLinks?.map(sml => {
    return {
      ...sml,
      blockId: sml.link.platform,
    };
  });
  const text = `
  - **BUY**
  - [Shipping & Returns](/p/about)
  - [Order Tracking](/s)
  - [Authentication Service](/l/new)
  `;

  const regex = /\[([^\]]+)\]\(([^)]+)\)/g;

  const matches = [];
  let match;

  while ((match = regex.exec(text)) !== null) {
    matches.push({ label: match[1], path: match[2] });
  }

  console.log(matches);
  const showSocialMediaLinks = socialMediaLinks?.length > 0;

  // use block builder instead of mapping blocks manually

  return (
    <SectionContainer
      as="footer"
      id={sectionId}
      className={className || css.root}
      rootClassName={rootClassName}
      appearance={appearance}
      options={fieldOptions}
      isFooter={true}
    >
      <div className={css.footer}>
        <div className={css.footerDesktop}>
          <div className={css.footerDContainer}>
            <BlockBuilder blocks={blocks} sectionId={sectionId} options={options} />
          </div>
          <div className={css.logoContainer}>
            <LinkedLogo
              rootClassName={css.logoLink}
              logoClassName={css.logoWrapper}
              logoImageClassName={css.logoImage}
            />

            <div className={css.sloganDesktop}>
              <Field data={newSlogan} className={css.slogan} />
            </div>
          </div>
          <div className={css.divider} />
          <div className={css.secondLast}>
            <div className={css.links}>
              <NamedLink name="TermsOfServicePage" className={css.link}>
                <FormattedMessage id="SectionFooter.SectionFooter.termsOfServiceLabel" />
              </NamedLink>
              <NamedLink name="PrivacyPolicyPage" className={css.link}>
                <FormattedMessage id="SectionFooter.SectionFooter.privacyPolicy" />
              </NamedLink>
              <NamedLink name="CMSPage" params={{ pageId: 'cookie-policy' }} className={css.link}>
                <FormattedMessage id="SectionFooter.StaticFooter.cookiePolicy" />
              </NamedLink>

              <a
                href="javascript:;"
                className={css.cookieManagerButton}
                onClick={CookieConsent.showPreferences}
              >
                <FormattedMessage id="SectionFooter.CookieManager" />
              </a>
            </div>
            <div className={css.socailContainer}>
              {showSocialMediaLinks ? (
                <div className={css.icons}>
                  <BlockBuilder blocks={linksWithBlockId} sectionId={sectionId} options={options} />
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div className={css.footerMobile}>
          <FooterMobile
            blocks={blocks}
            sectionId={sectionId}
            options={options}
            showSocialMediaLinks={showSocialMediaLinks}
            linksWithBlockId={linksWithBlockId}
            newSlogan={newSlogan}
          />
        </div>
        {/* <div className={classNames(css.content, getContentCss(numberOfColumns))}>
          <div>
            <LinkedLogo
              rootClassName={css.logoLink}
              logoClassName={css.logoWrapper}
              logoImageClassName={css.logoImage}
            />
          </div>
          <div className={css.sloganMobile}>
            <Field data={newSlogan} className={css.slogan} />
          </div>
          <div className={css.detailsInfo}>
            <div className={css.sloganDesktop}>
              <Field data={newSlogan} className={css.slogan} />
            </div>
            {showSocialMediaLinks ? (
              <div className={css.icons}>
                <BlockBuilder blocks={linksWithBlockId} sectionId={sectionId} options={options} />
              </div>
            ) : null}
            <Field data={newCopyright} className={css.copyright} />
          </div>
          <div className={classNames(css.grid, getGridCss(numberOfColumns))}>
            <BlockBuilder blocks={blocks} sectionId={sectionId} options={options} />
            <a
              href="javascript:;"
              className={css.cookieManagerButton}
              onClick={CookieConsent.showPreferences}
            >
              <FormattedMessage id="SectionFooter.CookieManager" />
            </a>
          </div>
        </div> */}
      </div>
      <div className={css.last}>
        <StaticFooter
          cookieContent={
            <a
              href="javascript:;"
              className={css.cookieManagerButton}
              onClick={CookieConsent.showPreferences}
            >
              <FormattedMessage id="SectionFooter.CookieManager" />
            </a>
          }
        />
        <div className={css.lastCopyright}>
          <Field data={newCopyright} className={css.copyright} />
        </div>
      </div>
    </SectionContainer>
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

SectionFooter.defaultProps = {
  className: null,
  rootClassName: null,
  textClassName: null,
  numberOfColumns: 1,
  socialMediaLinks: [],
  slogan: null,
  copyright: null,
  appearance: null,
  blocks: [],
  options: null,
};

SectionFooter.propTypes = {
  sectionId: string.isRequired,
  className: string,
  rootClassName: string,
  numberOfColumns: number,
  socialMediaLinks: arrayOf(object),
  slogan: object,
  copyright: object,
  appearance: object,
  blocks: arrayOf(object),
  options: propTypeOption,
};

export default SectionFooter;
