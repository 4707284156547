import React from 'react';
import { func, node, object, shape, string } from 'prop-types';
import classNames from 'classnames';

import Field, { hasDataInFields } from '../../Field';
import BlockContainer from '../BlockContainer';

import css from './BlockDefault.module.css';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const FieldMedia = props => {
  const { className, media, sizes, isLandingPage, isHideButtons, options } = props;
  const hasMediaField = hasDataInFields([media], options);
  return hasMediaField ? (
    <div className={classNames(className, css.media)}>
      <Field
        data={media}
        sizes={sizes}
        options={options}
        isLandingPage={isLandingPage}
        isHideButtons={isHideButtons}
      />
    </div>
  ) : null;
};

const BlockDefault = props => {
  const {
    blockId,
    className,
    rootClassName,
    mediaClassName,
    textClassName,
    ctaButtonClass,
    title,
    text,
    callToAction,
    media,
    responsiveImageSizes,
    options,
    isLandingPage,
    sectionId,
  } = props;

  const isHideButtons =
    (isLandingPage && sectionId == 'section-2') || (isLandingPage && sectionId == 'section-4');
  const history = useHistory();
  const href = callToAction?.href;

  const classes = classNames(rootClassName || css.root, className);
  const hasTextComponentFields = hasDataInFields([title, text, callToAction], options);

  return (
    <BlockContainer
      id={blockId}
      className={classes}
      isHideButtons={isHideButtons}
      isLandingPage={isLandingPage}
    >
      {isLandingPage ? (
        <>
          <div
            className={css.imageClick}
            onClick={() => {
              if (href) {
                history.push(href);
              }
            }}
          >
            <FieldMedia
              media={media}
              sizes={responsiveImageSizes}
              className={mediaClassName}
              options={options}
              isLandingPage={isLandingPage}
              isHideButtons={isHideButtons}
            />
          </div>
          {hasTextComponentFields && !isHideButtons ? (
            <div className={classNames(textClassName, css.text)}>
              <Field data={title} options={options} />
              <Field data={text} options={options} />
              {/* {isHideButtons ? null : ( */}
              <Field data={callToAction} className={ctaButtonClass} options={options} />
              {/* )} */}
            </div>
          ) : null}
        </>
      ) : (
        <>
          {' '}
          <FieldMedia
            media={media}
            sizes={responsiveImageSizes}
            className={mediaClassName}
            options={options}
          />
          {hasTextComponentFields ? (
            <div className={classNames(textClassName, css.text)}>
              <Field data={title} options={options} />
              <Field data={text} options={options} />
              <Field data={callToAction} className={ctaButtonClass} options={options} />
            </div>
          ) : null}
        </>
      )}
    </BlockContainer>
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

BlockDefault.defaultProps = {
  className: null,
  rootClassName: null,
  mediaClassName: null,
  textClassName: null,
  ctaButtonClass: null,
  title: null,
  text: null,
  callToAction: null,
  media: null,
  responsiveImageSizes: null,
  options: null,
};

BlockDefault.propTypes = {
  blockId: string.isRequired,
  className: string,
  rootClassName: string,
  mediaClassName: string,
  textClassName: string,
  ctaButtonClass: string,
  title: object,
  text: object,
  callToAction: object,
  media: object,
  responsiveImageSizes: string,
  options: propTypeOption,
};

export default BlockDefault;
