import React, { useState } from 'react';
import css from './SectionFooter.module.css';
import Collapsible from 'react-collapsible';
import { IoIosArrowDown } from 'react-icons/io';
import { IoIosArrowUp } from 'react-icons/io';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { NamedLink } from '../../../../components';
import { FormattedMessage } from 'react-intl';
import { GrLanguage } from 'react-icons/gr';
import { updateProfileLanguage } from '../../../../ducks/user.duck';
import { useDispatch } from 'react-redux';
const StaticFooter = ({ cookieContent }) => {
  const [isOpen, setIsOpen] = useState({});
  const history = useHistory();
  const dispatch = useDispatch();
  const handleOnChange = async language => {
    await dispatch(updateProfileLanguage(language));
    document.cookie = 'language=' + language;
    window.location.reload(true);
  };
  return (
    <div className={css.staticFooter}>
      <div className={css.collapsibleStatic}>
        <Collapsible
          key={'terms'}
          onOpen={e => {
            setIsOpen({ ...isOpen, terms: true });
          }}
          onClose={e => {
            setIsOpen({ ...isOpen, terms: false });
          }}
          trigger={
            <div className={css.triggerStatic}>
              <span className={css.label}>
                <FormattedMessage id="SectionFooter.StaticFooter.termsOfService" />
              </span>
              <span className={css.iconContainer}>
                {isOpen?.terms ? <IoIosArrowUp /> : <IoIosArrowDown />}
              </span>
            </div>
          }
        >
          <ul className={css.linksMobileStatic}>
            <NamedLink name="TermsOfServicePage" className={css.mobileLabel}>
              <FormattedMessage id="SectionFooter.SectionFooter.termsOfServiceLabel" />
            </NamedLink>
            <NamedLink name="PrivacyPolicyPage" className={css.mobileLabel}>
              <FormattedMessage id="SectionFooter.SectionFooter.privacyPolicy" />
            </NamedLink>
          </ul>
        </Collapsible>
      </div>
      <div className={css.collapsibleStatic}>
        <Collapsible
          key={'cookies'}
          onOpen={e => {
            setIsOpen({ ...isOpen, cookies: true });
          }}
          onClose={e => {
            setIsOpen({ ...isOpen, cookies: false });
          }}
          trigger={
            <div className={css.triggerStatic}>
              <span className={css.label}>
                <FormattedMessage id="SectionFooter.StaticFooter.cookies" />
              </span>
              <span className={css.iconContainer}>
                {isOpen?.cookies ? <IoIosArrowUp /> : <IoIosArrowDown />}
              </span>
            </div>
          }
        >
          <ul className={css.linksMobileStatic}>
            <li className={css.mobileLabel}>
              <NamedLink
                name="CMSPage"
                params={{ pageId: 'cookie-policy' }}
                className={css.mobileLabel}
              >
                <FormattedMessage id="SectionFooter.StaticFooter.cookiePolicy" />
              </NamedLink>
            </li>
            <li className={css.mobileLabel}>{cookieContent}</li>
          </ul>
        </Collapsible>
      </div>
      <div className={css.collapsibleStatic}>
        <Collapsible
          key={'language'}
          onOpen={e => {
            setIsOpen({ ...isOpen, language: true });
          }}
          onClose={e => {
            setIsOpen({ ...isOpen, language: false });
          }}
          trigger={
            <div className={css.triggerStatic}>
              <span className={css.label}>
                {' '}
                <FormattedMessage id="SectionFooter.StaticFooter.language" /> <GrLanguage />
              </span>
              <span className={css.iconContainer}>
                {isOpen?.language ? <IoIosArrowUp /> : <IoIosArrowDown />}
              </span>
            </div>
          }
        >
          <ul className={css.linksMobileStatic}>
            <li
              className={css.mobileLabel}
              onClick={() => {
                handleOnChange('el');
              }}
            >
              <FormattedMessage id="SectionFooter.StaticFooter.greek" />
            </li>
            <li
              className={css.mobileLabel}
              onClick={() => {
                handleOnChange('en-US');
              }}
            >
              <FormattedMessage id="SectionFooter.StaticFooter.english" />
            </li>
          </ul>
        </Collapsible>
      </div>
    </div>
  );
};

export default StaticFooter;
