import React, { useState } from 'react';
import css from './SectionFooter.module.css';
import Collapsible from 'react-collapsible';
import { IoIosArrowDown } from 'react-icons/io';
import { IoIosArrowUp } from 'react-icons/io';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import BlockBuilder from '../../BlockBuilder';

const FooterMobile = props => {
  const { blocks, sectionId, options, showSocialMediaLinks, linksWithBlockId, newSlogan } = props;
  const history = useHistory();
  if (blocks.length === 0) return null;
  const [isOpen, setIsOpen] = useState({});

  return (
    <div className={css.footerMobile}>
      {blocks.map(block => {
        const text = block?.text?.content;
        if (!text) return null;
        const titleMatch = text.match(/-\s\*\*(.+?)\*\*/);
        const title = titleMatch ? titleMatch[1].trim() : null;
        const regex = /\[([^\]]+)\]\(([^)]+)\)/g;
        const matches = [];
        let match;
        while ((match = regex.exec(text)) !== null) {
          matches.push({ label: match[1], path: match[2] });
        }

        const isOpenTab = isOpen[block.blockId];
        return (
          <div className={css.collapsible}>
            <Collapsible
              key={block.blockId}
              onOpen={e => {
                setIsOpen({ ...isOpen, [block.blockId]: true });
              }}
              onClose={e => {
                setIsOpen({ ...isOpen, [block.blockId]: false });
              }}
              trigger={
                <div className={css.trigger}>
                  <b>{title}</b>
                  {isOpenTab ? <IoIosArrowUp /> : <IoIosArrowDown />}
                </div>
              }
            >
              <ul className={css.linksMobile}>
                {matches.map((match, index) => (
                  <li key={index} onClick={() => history.push(match.path)}>
                    {match.label}
                  </li>
                ))}
              </ul>
            </Collapsible>
          </div>
        );
      })}
      {showSocialMediaLinks ? (
        <div className={css.mobileSocial}>
          <BlockBuilder blocks={linksWithBlockId} sectionId={sectionId} options={options} />
        </div>
      ) : null}
      {newSlogan && newSlogan?.content ? (
        <div className={css.mobileSocial}>
          <p className={css.sloganMobile}>{newSlogan?.content}</p>
        </div>
      ) : null}
    </div>
  );
};

export default FooterMobile;
